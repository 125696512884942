import BunpunLogo from '../assets/bunpun.svg';
import dayjs from 'dayjs';

export const GlobalImages = {
    BunpunLogo,
};

export const drawerWidth = 72;

export const todayFilter = { 
    from: dayjs().startOf('day').toISOString(), to: dayjs().endOf('day').toISOString()
    // from: '2024-04-18', to: '2024-05-13'
};

export const formatDate = (date) => dayjs(date).format('DD/MM/YYYY');
export const formatDateAndTime = (date) => dayjs(date).format('DD/MM/YYYY hh:mm a');
export const formatDateAndTimeTimeZone = (date) => dayjs(date).format('DD/MM/YYYY hh:mm a Z');

export const formatNumber = (number) => {
    const largeSuffixes = ["M", "B", "T"];
    const millionThreshold = 1000000; // Minimum for "M" suffix
  
    if (number < millionThreshold) {
      return Math.round(number); // No suffix for numbers below threshold
    }
  
    const exponent = Math.floor(Math.log(number / millionThreshold) / Math.log(1000)); // Adjust exponent calculation
    const formattedNumber = parseFloat((number / (1000 ** exponent * millionThreshold)).toFixed(1));
    const suffix = largeSuffixes[exponent];
    return `${formattedNumber}${suffix}`;
};

export const ADMIN_ROLE = Object.freeze({
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    SUPERVISOR: 'SUPERVISOR',
});