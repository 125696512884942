import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Tooltip,
} from '@mui/material';
import { drawerWidth } from '../../utils';
import Stack from '@mui/material/Stack';
import Gear from '../../assets/icons/SettingIcon';
import LogoIcon from '../../assets/icons/logoIcon';
import DashboardIcon from '../../assets/icons/DashboardIcon';
import UsersIcon from '../../assets/icons/UserIcon';
import TasksIcon from '../../assets/icons/TaskIcon';
import WithdrawalIcon from '../../assets/icons/WithdrawalIcon';
import ROUTES from '../../routes';
import api from '../../config/api';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FeedbackIcon from '../../assets/icons/FeedbackIcon';
import ReferralIcon from '../../assets/icons/ReferralIcon';
import LogoutIcon from '../../assets/icons/LogoutIcon';
import TransactionIcon from '../../assets/icons/TransactionIcon';

const menu = [
  {
    label: 'Dashboard',
    icon: <DashboardIcon variant="light" size={24} />,
    link: ROUTES.DASHBOARD,
  },
  {
    label: 'Users',
    icon: <UsersIcon variant="light" size={24} />,
    link: ROUTES.USERS,
  },
  {
    label: 'Tasks',
    icon: <TasksIcon variant="light" size={24} />,
    link: ROUTES.TASKS,
  },
  {
    label: 'Withdrawals',
    icon: <WithdrawalIcon variant="light" size={24} />,
    link: ROUTES.WITHDRAWALS,
  },
  {
    label: 'Transactions',
    icon: <TransactionIcon variant="light" size={24} />,
    link: ROUTES.TRANSACTIONS,
  },
  {
    label: 'Feedback',
    icon: <FeedbackIcon variant="light" size={24} />,
    link: ROUTES.FEEDBACKS,
  },
  {
    label: 'Referrals',
    icon: <ReferralIcon variant="light" size={24} />,
    link: ROUTES.REFERRALS,
  },
  {
    label: 'Settings',
    icon: <Gear variant="light" size={24} />,
    link: ROUTES.SETTINGS,
  },
  {
    label: 'Logout',
    icon: <LogoutIcon variant="light" size={24} />,
    link: '',
  },
];

export default function SideMenu1({ open, setOpen }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const logout = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      await api.get('/admin/api/logout');
      sessionStorage.clear();
      sessionStorage.clear();
      navigate(ROUTES.LOGIN);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          height: 'auto',
          boxSizing: 'border-box',
          backgroundColor: '#010101',
          left: '10px',
          top: '10px',
          bottom: '10px',
          borderRadius: '12px',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        height="100%"
        paddingTop="16px"
        paddingBottom="16px"
      >
        <Link to={ROUTES.DASHBOARD} style={{ color: 'gray', textDecoration: 'none' }}>
          <LogoIcon />
        </Link>
        <List>
          {menu.map((item, index) => (
            <Link
              key={item.label}
              to={item.link ? item.link : undefined}
              onClick={!item.link ? logout : undefined}
              style={{ color: 'gray', textDecoration: 'none' }}
            >
              <ListItem disablePadding>
                <Tooltip title={item.label} placement="right" arrow>
                  <ListItemButton>
                    <ListItemIcon
                      sx={{ minWidth: 0, color: '#fff', paddingTop: '5px' }}
                    >
                      {item.icon}
                    </ListItemIcon>
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </Link>
          ))}
        </List>
      </Stack>
    </Drawer>
  );
}
