import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

const BankDetails = ({ open, data, handleClose }) => {
    const styles = {
        box: {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          rowGap: '25px',
          columnGap: '16px',
          pt: 2, 
          pb: 6, 
        },
        item: {
          flexBasis: 'calc(50% - 16px)',
          boxSizing: 'border-box',
        }
    };
    
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle id="customized-dialog-title">
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent>
                <Typography variant='h4'>Bank Details</Typography>
                <Box sx={styles.box}>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Account Holder Name</Typography>
                        <Card>{data?.accHolderName}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Account Number</Typography>
                        <Card>{data?.accNo}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Bank Name</Typography>
                        <Card>{data?.bankName}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Bank Code</Typography>
                        <Card>{data?.code}</Card>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default BankDetails;