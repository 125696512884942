import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const CustomTextArea = ({ name, control, label, type = 'text', rules }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error }, formState }) => (
        <TextField
          {...field}
          helperText={error ? error.message : null}
          size='small'
          error={!!error}
          onChange={field.onChange}
          value={field.value}
          fullWidth
          multiline
          rows={4}
          autoComplete={'off'}
          sx={{
            mb: 1,
            '& textarea': {
              fontSize: '16px',
              fontWeight: 600,
            },
          }}
          InputProps={{
            style: {
              borderRadius: '12px',
              borderColor: 'rgba(242, 242, 242, 0.1) !important',
            },
          }}
          variant='outlined'
          placeholder={label}
          type={type}
        />
      )}
    />
  );
};
export default CustomTextArea;
