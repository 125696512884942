import React, { useMemo } from 'react';
import { Stack, useTheme, Button } from '@mui/material';
import TopHeading from '../../components/TopHeading/TopHeading';
import ReferralIcon from '../../assets/icons/ReferralIcon';
import useGetReferrals from '../../hooks/useGetReferrals';
import DataTable from '../../components/Table';
import { ADMIN_ROLE, formatDate } from '../../utils';
import CustomDateFilter from '../../components/CustomDateFilter';
import CustomSearch from '../../components/CustomSearch';
import { Box } from '@mui/material';
import Loading from '../../components/Loading/Loading';
import TableCell from '../../components/TableCell/TableCell';
import EventIcon from '../../assets/icons/EventIcon';
import useGetCounts from '../../hooks/useGetCounts';
import PlusIcon from '../../assets/icons/plusicon';
import EditIcon from '../../assets/icons/EditIcon';
import CircleIcon from '@mui/icons-material/Circle';
import TableCellWithDropdown from '../../components/TableCellWithDropdown/TableCellWithDropdown';
import { toast } from 'react-toastify';
import api from '../../config/api';
import Referral from './Referral';
import useGetSettings from '../../hooks/useGetSettings';
import NotificationIcon from '../../assets/icons/notification';

const Referrals = () => {
    const theme = useTheme();
    const [filter, setFilter] = React.useState({ page: 1, size: 7, search: '' });
    const [dateFilter, setSetDateFilter] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [row, setRow] = React.useState(null);
    const [isEdit, setIsEdit] = React.useState(false);
    const { data: settings } = useGetSettings();
    const { data: counts } = useGetCounts();
    const { data: referrals, isLoading, refetch } = useGetReferrals(filter);
    const isUserHasAccess = sessionStorage.getItem('role') === ADMIN_ROLE.SUPER_ADMIN;

    const handleDateChange = async (newDate) => setFilter({ page: 1, size: 7, search: '', ...newDate });
    const handleSearch = (search) => {
        filter?.search !== search && setFilter({ ...filter, page: 1, size: 7, search });
    }
    const handlePagination = (page, size) => setFilter({ ...filter, page, size });
    const handleCellClick = (row) => {
      if (row.field === 'details'){
        setRow(row?.row?.details);
        setOpen(true);
        setIsEdit(true);
      }
    }
    const handleClose = (update) => {
      setRow(null);
      setOpen(false);
      update === true && refetch();
    };
    const createReferral = () => {
      setIsEdit(false);
      setOpen(true);
    }

    const indicator = (color) => <CircleIcon sx={{ width: 10, color }} />;
    const list = [ 
      { value: false, text: 'Inactive', icon: indicator((theme) => theme.palette.error.dark) },
      { value: true, text: 'Active', icon: indicator((theme) => theme.palette.success.dark) },
    ];

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'name',
          renderHeader: (params) => <TableCell icon={<EventIcon size={24} />} title='Referral Event' />,
          width: 300,
          renderCell: (params) => {
            const name = params.row.name;
            return <TableCell icon={<EventIcon size={24} color={theme.palette.success.light} />} title={name} />;
          },
        },
        {
          field: 'startDate',
          headerName: 'Start Date',
          width: 200,
        },
        {
          field: 'endDate',
          headerName: 'End Date',
          width: 200,
        },
        {
          field: 'count',
          headerName: 'User Acquired',
          type: 'number',
          width: 160,
        },
        {
          field: 'details',
          headerName: 'Referral Detail',
          width: 170,
          headerAlign: 'center',
          renderCell: (params) => {
            return <Stack alignItems="center">
                <TableCell title={ isUserHasAccess ? 'Edit' : 'View'} endAdornment={
                  isUserHasAccess ? <EditIcon size={16}  color={theme.palette.success.light} /> : 
                  <NotificationIcon size={16} /> } />
              </Stack>;
          },
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 170,
          headerAlign: 'center',
          renderCell: (params) => {
            const status = params.row.status;
            return <TableCellWithDropdown 
            name={'status'} 
            list={list} 
            defaultValue={status} 
            disabled={!isUserHasAccess}
            onChange={(status) => handleOnChange(status, params.row.id)} />
          },
        }
    ];

    const rowData = useMemo(() => referrals?.data?.data?.map((referral) => ({
      id: referral._id,
      name: referral.name,
      startDate: formatDate(referral.startDate),
      endDate: formatDate(referral.endDate),
      count: referral.count,
      details: referral,
      status: referral.status
    })),[referrals])
    
    const handleOnChange = async (status, id) => {
      try {
        const { data: referral } = await api.put(`/admin/api/referral/${id}`, {status});
        toast.success('Updated Status Successfully');
        refetch();
        return true;
      } catch (error) {
          if (error?.response?.status === 400) {
            toast.warn(error.response?.data?.message || 'Status update failed!');
          }
          console.log(error);

          return false;
      }
    };
   
    if (isLoading) return <Loading />;
  return (
    <Stack spacing={3}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TopHeading icon={<ReferralIcon />} title={`Referral Events (${ counts?.data?.referralCount || 0 })`} />
          <CustomSearch onSearch={handleSearch} value={filter?.search} />
        </Box>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <CustomDateFilter dateChange={handleDateChange} dateFilter={dateFilter} setSetDateFilter={setSetDateFilter} />
          { isUserHasAccess && <Button variant='outlined_light' endIcon={<PlusIcon />} onClick={createReferral} >Create Referral</Button> }
        </Stack>
        <DataTable 
        columns={columns} 
        rows={rowData || []} 
        totalCount={referrals?.data?.totalCount || 0} 
        pagination={handlePagination} 
        pageSize={filter.size}
        page={filter.page}
        onCellClick={handleCellClick} 
        />
        { open && <Referral open={open} data={row} settings={settings} isEdit={isEdit} handleClose={handleClose} /> }
    </Stack>
  )
}

export default Referrals