import React from 'react'
import { Stack, Card, Button } from '@mui/material';
import TopHeading from '../../components/TopHeading/TopHeading';
import AddAdmin from './AddAdmin';
import PlusIcon from '../../assets/icons/plusicon';
import useGetAdmins from '../../hooks/useGetAdmin';
import { ADMIN_ROLE } from '../../utils';

const Admin = () => {
    const [showBtn, setShowBtn] = React.useState(true);
    const { data: admins, refetch } = useGetAdmins();
    const isUserHasAccess = sessionStorage.getItem('role') === ADMIN_ROLE.SUPER_ADMIN;

    const handleAddBtn = (status) => {
        setShowBtn(status);
        refetch()
    }

  return (
    <Stack spacing={3} pt={4}>
        <TopHeading title="Admin List" />
        { admins?.data?.map((admin, index) => (
            <Stack spacing={3} direction={'row'} key={index}>
                <Card variant='outlined' sx={{ width: '50%', flexGrow: 1, p: '12px' }} >{admin.name}</Card>
                <Card variant='outlined' sx={{ width: '50%', flexGrow: 1, p: '12px' }} >{admin.email}</Card>
            </Stack>
        ))}
        { isUserHasAccess &&
        (showBtn ? 
        <Button variant='outlined_light' sx={{ width: 100 }} endIcon={<PlusIcon />} onClick={() => setShowBtn(false)} >Add</Button> :
        <AddAdmin showAddBtn={handleAddBtn} />)
        }
    </Stack>
  )
}

export default Admin