import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import CustomTextField from '../CustomTextField/CustomTextField';
import CustomDropdown from '../CustomDropdown/CustomDropdown';

const CustomInputAndDropdown = ({ name, label, list, control, onChange, defaultValue, rules, placeholder }) => {
  return (
    <Stack spacing={1} sx={{ mt: '.5rem' }}>
      <Typography variant="h5_semibold">{label}</Typography>
      <Stack direction={'row'} spacing={1} >
        <Box sx={{ width: '50%' }} >
          <CustomTextField
            name={`${name}.value`}
            control={control}
            defaultValue={defaultValue?.input || ''}
            onChange={onChange}
            placeholder={placeholder}
            rules={{...rules, pattern: {
              value: /^[0-9]*\.?[0-9]*$/,
              message: 'Only numbers are allowed',
            },}}
            type='number'
          />
        </Box>
        <CustomDropdown
          name={`${name}.type`}
          control={control}
          list={list}
          onChange={onChange}
          defaultValue={defaultValue?.dropdown || ''}
        />
      </Stack>
    </Stack>
  );
};

export default CustomInputAndDropdown;
