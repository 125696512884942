import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller } from 'react-hook-form';
import { InputAdornment, Stack, Typography } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import BrokenCalenderIcon from '../../assets/icons/BrokenCalenderIcon';
import dayjs from 'dayjs';

const CustomDatePicker = ({ name, label, control, rules, min = dayjs(), max, ...rest }) => {

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error }, formState }) => {
        
        return (
          <Stack spacing={1} sx={{ mt: '.5rem' }}>
            <Typography variant='h5_semibold' > {label} </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                {...field}
                onChange={field.onChange}
                value={field.value || null}
                minDate={min || dayjs()}
                maxDate={max || null}
                {...rest}
                slots={{
                  textField: (props) => (
                    <TextField
                      {...props}
                      helperText={error ? error.message : null}
                      size='small'
                      error={!!error}
                      fullWidth
                      sx={{
                        mb: 1,
                        '& input': {
                            padding: '12px',
                        },
                        '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'rgba(242, 242, 242, 1)',
                        },
                        '&:hover fieldset': {
                            borderColor: 'rgba(242, 242, 242, 1)',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'rgba(242, 242, 242, 1)',
                        },
                        },
                      }}
                      InputProps={{
                        style: {
                          borderRadius: '8px',
                          borderColor: '#F2F2F2',
                        },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <BrokenCalenderIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant='outlined'
                      placeholder={label}
                    />
                  ),
                }}
              />
            </LocalizationProvider>
          </Stack>
        );
      }}
    />
  );
};

export default CustomDatePicker;
