import React from 'react'
import { Stack, Box } from '@mui/material';
import TopHeading from '../../components/TopHeading/TopHeading';
import SettingIcon from '../../assets/icons/SettingIcon';
import { useForm } from 'react-hook-form';
import CustomTextFieldWithLabel from '../../components/CustomTextFieldWithLabel/CustomTextFieldWithLabel';
import Admin from './Admin';
import useGetSettings from '../../hooks/useGetSettings';
import api from '../../config/api';
import { toast } from 'react-toastify';
import { ADMIN_ROLE } from '../../utils';

export default function Settings() {
  const [updateSettings, setUpdateSettings] = React.useState(null);
  const { handleSubmit, control } = useForm();
  const { data: settings, refetch } = useGetSettings();
  const isUserHasAccess = sessionStorage.getItem('role') === ADMIN_ROLE.SUPER_ADMIN;
  const onSubmit = async (value) => {}
  
  React.useEffect(() => {
    if(settings && updateSettings){
      const getData = setTimeout(async() => {
        try {
          const { data } = await api.post(`/admin/api/settings`, {id: settings?.data?._id, ...updateSettings});
          toast.success('Updated Successfully');
          setUpdateSettings(null)
          refetch();
        } catch (error) {
            console.log(error);
        }
      }, 1000)
      return () => clearTimeout(getData);
    }
}, [updateSettings, 1000]);
  const handleOnChange = async (key, value) => {
    setUpdateSettings({ [key]: value })
  }

  const setting = [
    { label: 'Platform Fee (percentage)', name: 'platformFeePercentage', value: settings?.data?.platformFeePercentage },
    { label: 'Minimum Wallet Balance', name: 'minimumBalance', value: settings?.data?.minimumBalance},
    { label: 'Minimum Withdrawal Amount', name: 'minWithdraw', value: settings?.data?.minWithdraw},
    { label: 'Set Welcome Offer to no. of Users', name: 'welcomeOfferUsers', value: settings?.data?.welcomeOfferUsers},
  ];

  return (
    <Stack>
        <TopHeading icon={<SettingIcon />} title="Settings" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} direction={{ md: 'row', sm: 'column', xs: 'column' }} >
            { settings && setting?.map((input, index) => (
              <Box sx={{ flexGrow: 1 }} key={index}>
                <CustomTextFieldWithLabel
                  name={input.name}
                  control={control}
                  label={input.label}
                  defaultValue={input.value}
                  onChange={(e) => handleOnChange(input.name, e.target.value)}
                  disabled={!isUserHasAccess}
                />
              </Box>
            ))}
          </Stack>
        </form>
        <Admin />
    </Stack>
  )
}
