import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Select, InputBase, MenuItem, Stack, Typography  } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomInput = styled(InputBase)({
    minWidth: "150px",
    color: "#010101",
    height: "50px",
    padding: '10px 10px 10px 20px',
});

const TableCellWithDropdown = ({ 
    name,
    rules,
    list,
    onChange,
    defaultValue,
    label,
    disabled = false,
    }) => {

    const { control } = useForm();

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            disabled={disabled}
            render={({ field, }) => (
            <Select
                {...field}
                onChange={async(e) => {
                    const status = await onChange(e.target.value);
                    status && field.onChange(e); // when updated the backend then will change
                }}
                value={field.value}
                input={<CustomInput />}
                variant='outlined'
                placeholder={label}
            >
                { list.map((item, index) => (<MenuItem
                 key={item.value} 
                 value={item.value}
                 >  
                    <Stack spacing={2} direction={'row'} justifyContent={'left'}>
                        {item.icon}
                        <Typography variant='span'>{item.text}</Typography>
                    </Stack>
                </MenuItem>)) }
            </Select>
            )}
        />
    )
}

export default TableCellWithDropdown;